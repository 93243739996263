@import "./src/styles/_mixin.scss";
.HeaderArea{
    position: relative;
    margin-top: 80px;

    .user{
        position: absolute;
        top: -70px;
        right: -5px;

        .profile{
            float: left;
            background-color: #435982;
            color: #fff;
            border-radius: 100px;
            padding: 5px 10px 5px 10px;
            line-height: 24px;
        }
       
        .settingIcon{
            float: right;
            width: 34px;
            height: 34px;
            margin-left: 5px;
            color: #a8a8a8;
            transition: 0.3s;
            cursor: pointer;
            &:hover{
                color: #435982;
            }
        }

        .logoutIcon{
            float: right;
            width: 34px;
            height: 34px;
            margin-left: 5px;
            color: #a8a8a8;
            transition: 0.3s;
            cursor: pointer;
            &:hover{
                color: #435982;
            }
        }
        
        
        .userIcon{
            float: left;
        }
        
        span{
            display: inline-block;
            margin: 0 8px;
        }
    }


    .utils{
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        height: 40px;
        svg{
            width: 100%;
            height: 100%;
            color: #aaa;
            transition: 0.3s;

            &:hover{
                color: #435982;
            }
        }
        .btnUtil{
            position: absolute;
            width: 35px;
            height: 40px;
            top: 0;
            cursor: pointer;

            &.on svg{
                color: #435982;
            }
        }
        .btnUser{
            right: 50px;
            
        }
        .btnSetting{
            right: 0;
        }
    }
    
    .menuList{
        display: inline-block;
        &:nth-last-child(1){
            margin-right: 0;
        }
        
        a{
            border: 1px solid #eee;
            display: inline-block;
            margin-right: 1rem;
            background-color: #eee;
            padding: 10px 15px;
            cursor: pointer;
            height: 43px;
            background-color: #fff;
            color: #000;
            border: none;
            box-shadow: 5px 5px 6px 1px #eee;
            transition: 0.2s;
            text-transform: uppercase;
            border-radius: 3px 3px 0px 0px;

            &:hover{
                background-color: #435982;
                color: #fff;

            }

            
        }

        &.on a{
            background-color: #435982;
            color: #fff;
        }
    }
}

