@import "./src/styles/_mixin.scss";
.loadingArea{
  text-align: center;
  img{
    width: 50px;
    height: 50px;
    margin: 20px 0px;
  }
}

.ListDetailPage{

  
    h3{
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-top: 80px;

        
    }

    .naming h3{
        margin-top: 40px;
    }

    

    .inputDesc{
        display: inline-block;
        height: 30px;
        width: 200px;
        margin-bottom: 2rem;
        line-height: 1.8rem;
        color: #999;
        font-size: 0.9rem;
    }
    .inputText{
        display: inline-block;
        height: 30px;
        vertical-align: bottom;
        margin-bottom: 2rem;
        width: calc(100% - 200px);
        background-color: #f5f6f8;
        border: none;
        border-radius: 3px;
        padding-left: 10px;

        &.short{
            width: calc(100% - 350px);
        }
    }

    .row{
      position: relative;
      .inputText{
        width: calc(100% - 290px);
      }
    }

    .buttonArea{
        position: relative;
        height: 70px;
        background-color: #f5f6f8;
    }
    .listBtn{
        width: 80px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        border-radius: 2px;
        transition: 0.2s;
        background-color: #fff;
        padding: 4px;
        text-align: center;
        box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 8%);

        &:hover{
            opacity: 1;
            background-color: #435982;
            color: #fff;
        }
    }
    .btnSubmit{
        background-color: #435982;
        color: #fff;
        right: 110px;
    }
    .btnClose{
        // background-color: rgb(250, 89, 89);
        right: 17px;
    }

    .btnLink{
        top: 0;
        right: 0;
        background-color: #435982;
        color: #fff;
        line-height: 1.4rem;
    }
    .input_radioArea{
        display: inline-block;
        margin-bottom: 2rem;
        vertical-align: bottom;
        p{
          display: inline-block;
          cursor: pointer;
          &:nth-last-child(1){
            margin-left: 20px;
          }
        }
        
        label{
          font-size: 0.9rem;
          cursor: pointer;
        }
        
        input[type="radio"] {
          display: none;
        }
        
        input[type="radio"]+label:before{
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          padding: 3px;
          margin-right: 5px;
          background-clip: content-box;
          border: 2px solid #e7e6e7;
          background-color: #e7e6e7;
          border-radius: 50%;
        }
    
        input[type="radio"]:checked + label:before {
          background-color: #4e527d;
        }
        input[type="radio"]:disabled + label,
        input[type="radio"]:disabled + label::before{
          
          cursor: no-drop;
        }
        input[type="radio"]:disabled:checked + label::before{
          background-color: #aaa;
        }
    
        label {
          display: flex;
          align-items: center;
        }

        &.inputLock{
            margin-left: 20px;
        }
      }

      .iconUpload{
        .inputDesc.top{
          vertical-align: top;
        }
        margin-bottom: 40px;
        .iconPreview{
            display: inline-block;
            width: 120px;
            height: 120px;
            background: #fff;
            padding: 5px;
            box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 8%);

            img{
              width: 100%;
              height: 100%;
              display: block;
            }
        }

        label{
          display: inline-block;
          height: 30px;
          vertical-align: bottom;
          width: calc(100% - 430px);
          background-color: #f5f6f8;
          border: none;
          border-radius: 3px;
          padding: 3px;
          cursor: pointer;
          vertical-align: super;
          margin-left: 15px;
          font-size: 0.9rem;
          padding: 3px;
          padding-left: 10px;
          line-height: 1.7rem;
          
          svg{
            float: left;
            width: 18px;
            margin-right: 5px;
          }
        }

        .btnDelete{
          position: relative;
          display: inline-block;
          background-color: #435982;
          color: #fff;
          line-height: 1.4rem;
          vertical-align: super;
          margin-left: 0.7rem;
          top: 0;
        }

        .inputFile{
          display: none;
          height: 30px;
          vertical-align: bottom;
          width: calc(100% - 200px);
          background-color: #f5f6f8;
          border: none;
          border-radius: 3px;
          padding: 3px;
          cursor: pointer;
          vertical-align: super;
          margin-left: 15px;

          &.short{
              width: calc(100% - 415px);
          }

          
        }

        .inputFileLabel{
          display: inline-block;
          width: 100%;
          height: 250px;
          margin-bottom: 2rem;
          background-color: #f5f6f8;
          border: none;
          min-width: 100%;
          max-width: 100%;
          min-height: 200px;
          padding: 1rem 1.5rem;
          outline: none;
        }
      }

      .iconUpload.reject{
        label{
          background-color: #ddd;
          cursor: no-drop;
        }

        .btnDelete{
          background-color: #ddd;
          cursor: no-drop;
        }
      }


      
}