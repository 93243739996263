@import "./src/styles/_mixin.scss";
.ListPage{
    .list{
        position: relative;
        background-color: #fff;
        height: 70px;
        border-radius: 2px;
        margin: 1.5rem 0;
        padding: 5px 15px;
        box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 4%);

        &:nth-child(1){
            margin-top: 0;
        }

        &:hover{
            background-color: #eee;
        }

        .iconImg{
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-right: 20px;
            vertical-align: bottom;
        }

        .listAddress{
            width: calc(100% - 170px);
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 1.2rem 0.5rem;

            a{
                cursor: pointer;
                span{
                    padding-bottom: 4px;
                    font-size: 15px;
                    color: #000;
                    line-height: 1.5rem;
                    &:hover{
                        border-bottom: 1px solid #000;
                    }
                }
            }

            &.full{
                width: 100%;
            }

            .noResult{
                width: 100%;
                display: block;
                text-align: center;
            }
        }

        .listBtn{
            width: 80px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            top: 20px;
            border-radius: 2px;
            transition: 0.2s;
            background-color: #fff;
            padding: 4px;
            text-align: center;
            box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 8%);
    
            &:hover{
                opacity: 1;
                background-color: #435982;
                color: #fff;
            }
        }
        .btnSubmit{
            background-color: #435982;
            color: #fff;
            right: 110px;
        }
        .btnClose{
            // background-color: rgb(250, 89, 89);
            right: 17px;
        }

        .btnDetail{
            background-color: #435982;
            border-radius: 100px;
            color: #fff;
            right: 17px;
            width: 90px;
            padding: 5px 10px;
            svg{
                font-size: 1.3rem;
                float: left;
            }
        }
    }
}

.listSubTab{
    width: 120px;
    height: 30px;
    margin-bottom: 30px;
    margin-right: 20px;
    border-radius: 50px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #aaa;
    transition: 0.2s;
    box-shadow: 1px 1px 2px 1px #eee;

    &:hover{
        background-color: #435982;
        color: #fff;
    }

    &.on{
        background-color: #435982;
        color: #fff;
    }
}

.noImg{
    background-color: #ddd;
    border-radius: 100px;
}