@import "./src/styles/_mixin.scss";
.nftInventoryArea{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inventoryul{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .nftList{
      width: 25%;
      padding: 1rem;

      .imgArea{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        width: 100%;
        border: 1px solid #eee;
        border-radius: 10px;
        min-height: 250px;
        height: 250px;
      }
      img{
        padding: 1rem;
        width: max-content;
        height: max-content;
        max-width: 100%;
        max-height: 100%;
      }

      .desc{
        p{
          display: flex;
          font-size: 0.8rem;
          margin-bottom: 8px;
          .key{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            min-width: 70px;
            font-weight: bold;
          }
          .val{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            input{
              margin: 0;
              margin-right: 5px;
            }

            .btnSync{
             background-color: #435982;
             color: #fff;
             border-radius: 5px;
             width: 70px;
             height: 22px;
             .loadingImg{
              width: 20px;
              height: 22px;
              padding: 3px;
             }
             .success{
              color: chartreuse;
             }
             .fail{
              color: red;
             }
            }
          }
        }
        
      }

      
    }
  }

  .pagination{
    margin-top: 3rem;
  }
}