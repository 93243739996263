@import "./src/styles/_mixin.scss";
.DownloadPopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.4);
  z-index: 3;

  .popupCont{
    position: absolute;
    width: 400px;
    height: 155px;
    background-color: #fff;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    overflow: hidden;
    left: 50%;
    top: 50%;

    .popupConthd{
      h3{
        background: #435982;
        margin: 0;
        padding: 10px 15px;
        font-size: 1rem;
        color: #fff;
      }

      .popupClose{
        position: absolute;
        top: 6px;
        right: 5px;
        color: #fff;
        cursor: pointer;
      }
    }

    .datePicker{
      padding: 20px 30px;

      label{
        display: inline-block;
        p{
          padding-left: 5px;
          margin-bottom: 5px;
          svg{
            width: 18px;
            vertical-align: bottom;
            margin-right: 5px;
          }
        }
      }
      .select{
        padding-left: 10px;
        border: none;
        background-color: #f5f6f8;
        border-radius: 100px;
        width: 100px;
        height: 30px;
        font-size: 1rem;
        margin-right: 15px;
      }
      button{
        width: 100px;
        height: 30px;
        background-color: #435982;
        color: #fff;
        border-radius: 100px;
      }
    }
  }
}