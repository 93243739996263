@import "./src/styles/_mixin.scss";
.inputTokenIdSearch{
    float: right;
    display: flex;
    border: 1px solid #eee;
    border-radius: 5px;
    padding-left:5px;

    &:hover{
        border-color: #bbb;
    }
    .inputSearch{
        width: 250px;
        border: none;
    }
    .btnSearch{
        display: flex;
        width: 40px;
        height: 30px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        background: none;

        svg{
            color: #000;
        }

    }
}