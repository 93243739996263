@import "./src/styles/_mixin.scss";
.LoginPage{
    min-height: 200px;
    .loginBox{

        margin-left: 50%;
        transform: translateX(-50%);
        h2{
            text-align: center;
        }
        .btnLogin{
            width: 190px;
            height: 44px;
            background-image: url('../images/btn-signin-with-google-hover.svg');
            background-repeat: no-repeat;
            background-color: #fff;
            background-position: center center;
            margin-left: 50%;
            transform: translateX(-50%);
            margin-top: 30px;
            margin-bottom: 30px;

            &:hover{
                background-image: url('../images/btn-signin-with-google.svg');
            }
        }
    }
    
}