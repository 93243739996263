@import "./src/styles/_mixin.scss";
.PendingTokenPage{
  position: relative;

  .filterArea{
    margin-bottom: 30px;
    .filter{
      font-size: 15px;
      width: 90px;
      height: 30px;
      text-align: center;
      line-height: 18px;
      border: 1px solid #ddd;
      color: #aaa;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 100px;
      margin-right: 20px;
      transition: 0.2s;
      box-shadow: 1px 1px 2px 1px #eee;
      &:nth-last-child(1){
        margin-right: 0;
      }

      &.on,&:hover{
        background-color: #435982;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .pendingTokenTable{
    .searchArea{
      .chainList{
        font-size: 1.0rem;
        width: 15%;
        height: 50px;
        margin-right: 10px;
      }
      .networkList{
        font-size: 1.0rem;
        width: 15%;
        height: 50px;
        margin-right: 10px;
      }
      #searchInput{
        font-size: 1.2rem;
        width: 56%;
        height: 50px;
        margin-right: 10px;
      }
      .searchBtn{
        font-size: 1.0rem;
        width: 10%;
        height: 50px;
      }

    }
  
    .pendingTokenList{
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      &.th{
        font-weight: bold;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        text-align: center;
      }
      &.td{
        padding: 10px 0;
        border-bottom: 1px solid #f8f8f8;
      }

      .pendingTokenItem{
        width: calc(100% / 5);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        &.id{
          width: 5%;
          text-align: center;
        }
        &.createdAt{
          width: 25%;
          min-width: 180px;
          text-align: center;
        }
        &.chain{
          width: 15%;
          max-width: 60px;
          text-align: center;
        }
        &.network{
          width: 15%;
          max-width: 60px;
          text-align: center;
        }
        &.address{
          width: 40%;
          text-align: center;
        }
      }
    }
  }

  .btnDetail{
    background-color: #435982;
    color: #fff;
    border-radius: 100px;
    cursor: pointer;
  }

  .urllink{
    width: 100%;
    background-color: #fff;
    color:#0072E5;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .downloadArea{
    text-align: right;
    padding: 20px 0px;
    .btnDownload{
      cursor: pointer;
      display: inline-block;
      span{
        color: #0072E5;
      }
  
      svg{
        vertical-align: bottom;
        width: 20px;
        height: 20px;
      }
    }
  }
  
}