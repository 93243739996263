@import "./src/styles/_mixin.scss";
.FailedTable{
  position: relative;
  margin: 3rem 0;

  .failedListfilterArea{
    position: relative;
    width: 100%;
    height: 30px;
    text-align: right;
    margin-bottom: 20px;
    
    .filter,.inputValue,.submit{
      display: inline-block;
      height: 30px;
      border:none;
      background-color: #f5f6f8;
      padding-left: 10px;
      border-radius: 4px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.02);
      border: 1px solid #eee;
      margin-left: 10px;
    }
    
  }
  .failedList{
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    &.th{
      font-weight: bold;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      text-align: center;
    }
    &.td{
      padding: 10px 0;
      border-bottom: 1px solid #f8f8f8;
      height: 45px;
    }

    .failedItem{
      width: calc(100% / 8);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      text-align: center;

      &.col01{
        width: 350px
      }

      &.col02{
        width: 30%;
      }

      &.col03{
        width: 10%;
      }
      
      &.col04{
        width: 10%;
      }
    }

    
    
  }

  .nodata{
    padding-top: 3rem;
    text-align: center;
    text-transform: capitalize;
  }

  .btnSync{
    background-color: #435982;
    color: #fff;
    border-radius: 5px;
    width: 70px;
    height: 22px;
    .loadingImg{
     width: 20px;
     height: 22px;
     padding: 3px;
    }
    .success{
     color: chartreuse;
    }
    .fail{
     color: red;
    }
   }
}