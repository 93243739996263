@import "./src/styles/_mixin.scss";
*{
  text-decoration: none;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
  background-image: -webkit-gradient(linear,left top,left bottom,from(#f8f9fa),to(#eaeef7));
  background-image: linear-gradient(180deg,#f8f9fa,#eaeef7);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #root{
    max-width: 1024px;
    min-width: 769px;
    margin: 0 auto;
  }
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(21, 21, 21, 0.6);
    z-index: 2;
    overflow: auto;
  }

  //::-webkit-scrollbar {
  //  width: 8px;
  //  height: 100%;
  //}
  //
  //::-webkit-scrollbar-thumb {
  //  background-color: rgba(#222, 0.6);
  //  border-radius: 4px;
  //  cursor: pointer;
  //}

  ul {
    margin: unset;
    padding: unset;
  }

  .common-tab {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0676aa;
      border-radius: 2px;
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;
      flex-direction: row;

      height: 40px;

      li {
        text-transform: uppercase;

        min-width: 100px;
        width: 100px;
        height: inherit;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(6, 118, 170, 0.1);
        color: #222;

        line-height: 1;

        // font:{
        //   size: 14px;
        //   weight: var(--bold-font);
        // }

        -webkit-transition: all 0.15s;
        transition: all 0.15s;

        &[data-item-selected="true"],
        &:hover {
          background-color: #0676aa;
          color: #fff;
        }

        //&:first-child{
        //  border-top-left-radius: 5px;
        //  border-bottom-left-radius: 5px;
        //}
        //
        //&:last-child{
        //  border-top-right-radius: 5px;
        //  border-bottom-right-radius: 5px;
        //}

        border-radius: 20px;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }

  .dropdown-container {
    cursor: pointer;
    overflow: hidden;
    display: block;

    &[data-enabled="true"] {
      > div {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+CiAgICA8cGF0aCBmaWxsPSIjMDA1RDc4IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBsNS45NzQgOEwxMiAuMTEyeiIvPgo8L3N2Zz4K");
        background-position: 98% 50%;
        background-repeat: no-repeat;
      }

      &[data-dropdown-opened="true"] {
        > div {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+CiAgICA8cGF0aCBmaWxsPSIjMDA1RDc4IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBsNS45NzQgOEwxMiAuMTEyeiIgdHJhbnNmb3JtPSJyb3RhdGUoMTgwIDYgNCkiLz4KPC9zdmc+Cg==");
        }
      }
    }

    ul {
      position: absolute;
      width: inherit;
      display: block;
      overflow: auto;
      box-sizing: border-box;
      z-index: 1;

      box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.05);
      background: #fff;
      border-radius: 2px;

      margin-top: 12px;

      li {
        cursor: pointer;
      }
    }
  }

  .list-component {
    display: flex;
    flex-direction: column;

    .list-header {
      width: 100%;
      height: 40px;

      display: flex;
      flex-direction: row;
      align-items: center;

      border-bottom: 1px solid #e0e0e0;

      > span {
        display: flex;
        font: {
          size: 14px;
          weight: var(--bold-font);
        }

        &:nth-of-type(1) {
          justify-content: center;
        }
      }
    }

    .list-items {
      > ul {
        > li {
          display: flex;
          align-items: center;
          width: 100%;

          > {
            display: flex;
          }

          &:nth-child(odd) {
            background: #eef7f9;
            border-radius: 10px;
          }
        }
      }
    }
  }

  @keyframes gen-spinner {
    0% {
      height: 10px;
      opacity: 0.2;
    }
    50% {
      height: 20px;
      opacity: 1;
    }
    100% {
      height: 10px;
      opacity: 0.2;
    }
  }

  .loader {
    > svg path,
    > svg rect {
      fill: #007293;
    }

    &.loader--style8 {
      display: flex;
      align-items: center;

      .progress-rect {
        width: 4px;
        height: 20px;
        margin-right: 4px;
        opacity: 0.2;
        background: #007293 !important;
        position: relative;

        display: flex;
        align-items: center;
        will-change: height, opacity;
        transform: translate3d(0, 0, 0);

        &:last-child {
          margin-right: 0;
        }

        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            animation: gen-spinner 0.6s infinite;
            animation-delay: #{($i - 1) * 0.15}s;
          }
        }

        &[data-progress-type="long"] {
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              animation: gen-spinner 1.6s infinite;
              animation-delay: #{($i - 1) * 0.25}s;
            }
          }
        }
      }
    }
  }
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "./_styles.scss";
