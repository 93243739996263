@import "./src/styles/_mixin.scss";
.blackBack{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #000, $alpha: 0.4);
    z-index: 3;

    .popupArea{
        position: relative;
        width: 400px;
        min-height: 250px;
        background-color: #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .popupIcon{
        background-image: url('../../images/ic-modal-error.svg');
        height: 50px;
        margin-top: 40px;
        background-repeat: no-repeat;
        text-align: center;
        background-position-x: 50%;
    }

    .popupMsg{
        display: block;
        width: 100%;
        word-break: break-all;
        padding: 20px;
        min-height: 110px;
        text-align: center;
    }

    .popupBtnArea{
        height: 50px;
        .btnPopup{
            height: 100%;
            width: 50%;

            &.btnCancel{
                background-color:#f4f6f7;
                color: #999;
            }

            &.btnSubmit{
                background-color: #435982;
                color: #fff;
            }
            &.full{
                width: 100%;
            }
        }
    }

    
}