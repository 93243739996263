@import "./src/styles/_mixin.scss";
.blackBg{
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0,0,0,0.2);
    top: 0;
    left: 0;
    z-index: 1;
    &.close{
        width: 0;
        height: 0;
    }
}
.SettingPopup{
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: calc(100% - 50px);
    height: 100vh;
    max-width: 600px;
    max-height: 610px;
    background-color: #fff;
    box-shadow: 2px 2px 6px 2px rgb(0 0 0 / 4%);
    border-radius: 10px;
    
    overflow: hidden;
    transition: 0.2s;

    &.close{
        height: 0;
        box-shadow:none;
    }

    .popupHeader{
        height: 50px;
        padding: 0 30px;
        background-color: #435982;
        color: #fff;
        display: inline-block;
        width: 100%;
        height: 50px;
        font-size: 1.5rem;
        line-height: 50px;
        .btnClose{
            color: #fff;
            position: absolute;
            right: 0;
            width: 50px;
            height: 50px;
            background:none;
        }
    }
    .popupCont{
        padding: 20px 30px;
        height: calc(100% - 100px);
        overflow-y: scroll;

        .userObj{
            width: 100%;
            display: block;
            text-align: left;
            padding: 8px 20px;
            border-radius: 10px;
            margin-bottom: 10px;
            transition: 0.3s;
            height: 40px;
            box-shadow: 1px 1px 2px 1px #eee;
            overflow: hidden;

            &.on{
                height: 200px;
            }

            svg{
                color: #435982;
            }
            .userIcon{
                margin-right: 10px;
                cursor: pointer;
            }

            .userEmail{
                vertical-align: super;
                cursor: pointer;
                &:hover{
                    color: #435982;
                }
            }

            .userMode{
                float: right;
                border-radius: 100px;
                padding: 3px;
                transition: 0.3s;
                cursor: pointer;

                &:hover{
                    background-color: #435982;
                    color: #fff;
                }
            }

            .userRemove{
                float: right;
                margin-left: 10px;
                border-radius: 100px;
                padding: 3px;
                transition: 0.3s;
                cursor: pointer;
                &:hover{
                    background-color: #435982;
                    color: #fff;
                }
            }


        }

        .add{
            width: 100%;
            display: block;
            text-align: center;
            padding: 8px 0px;
            border-radius: 10px;
            transition: 0.3s;
            cursor: pointer;
            box-shadow: 1px 1px 2px 1px #eee;
            color: #435982;
            &:hover{
                background-color: #435982;
                color: #fff;
            }
        }

        .userDetails{
            padding: 10px 34px;
            .row{
                margin-bottom: 8px;
                border-bottom: 1px solid #eee;
                line-height: 26px;

                &:nth-last-child(1){
                    border-bottom: none;
                }
                .desc{
                    display: inline-block;
                    width: 140px;
                }
                .res{
                    display: inline-block;
                }
            }
        }
    }
    .popupFooter{
        padding: 0 30px;
        height: 60px;
        text-align: center;
        .submit{
            background-color: #435982;
            color: #fff;
            font-size: 1rem;
            padding: 5px 10px;
            border-radius: 5px;
        }

    }
    .addBlackbg{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        top: 0;
        left: 0;
    }
    .addPopup{
        width:80%;
        height: auto;
        background-color: #fff;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        overflow: hidden;
    }

    .inputItem{
        height: 40px;
        margin-bottom: 10px;
        border-radius: 100px;
        padding: 8px;
        box-shadow: 1px 1px 2px 1px #eee;

        span,input,svg{
            vertical-align: middle;
            margin: 0 8px;
        }

        label{
            span{
                margin: 0;
            }
        }

        .col{
            display: inline-block;
            width: 110px;
        }

        input[type=text],.adminClassList {
            padding-left: 10px;
            border: none;
            background-color: #f5f6f8;
            border-radius: 100px;
            width: 205px;
            height: 24px;
        }

        svg{
            color: #435982;
        }
    }
}

