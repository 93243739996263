@import "./src/styles/_mixin.scss";
.btnSyncAll{
  float: left;
  height: 30px;
  border:none;
  background-color: #435982;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.02);
  border: 1px solid #eee;
  padding: 4px 8px;
  cursor: pointer;
  width: 80px;
  .loadingImg{
    width: 20px;
    height: 22px;
    padding: 3px;
   }
   .success{
    color: chartreuse;
   }
   .fail{
    color: red;
   }
}