@import "./src/styles/_mixin.scss";
.copyButtonArea{
    position: relative;
    display: flex;
    width: 100%;

    .tokenId{
        display: inline-block;
        width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-top: 4px;
    }

    .btnCopy{
        display: flex;
        background-color: #435982;
        border-radius: 100px;
        padding: 4px;
        margin-left: 5px;
        svg{
            color: #fff;
            width: 14px;
            height: 14px;
        }
        .check{
            color: greenyellow;
        }
    }
}