#root{
  display: flex;
  flex-direction: column;

  .page{
    //margin-top: 80px;
  }

  --font-rules: 'Google Sans', Roboto, 'Noto Sans KR', sans-serif;
  font-family: var(--font-rules);

  --light-font: 300;
  --medium-font: 400;
  --bold-font: 500;
  --ultra-font: 600;

  --white: #fff;
}

textarea{
  font-family: var(--font-rules);
}

button{
  border: unset;
  //background: none no-repeat;
  font-family: var(--font-rules);
  cursor: pointer;
  user-focus: none;
  //margin: unset;
  /*letter-spacing: 0;*/

  &:focus{
    outline: none;
  }
}

input{
  //border: none;
  font-family: var(--font-rules);

  &:focus{
    outline: none;
  }
}

span{
  line-height: 1;
  font-family: var(--font-rules);
  //font-size: inherit;
  //font-weight: var(--light-font);

  &[data-type="address"]{
    font-family: "Roboto";
    letter-spacing: 0.3px;
  }
}

ul{
  //padding: unset;
  //margin: unset;
  list-style: none;
}

p{
  margin: unset;
  padding: unset;
  font-family: var(--font-rules);
}

strong{
  font-weight: var(--bold-font);
}