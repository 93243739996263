@import "./src/styles/_mixin.scss";
.contentsArea{
  position: relative;
  width: 100%;
  display: block;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba($color: #000000, $alpha: 0.1);
  padding: 40px 40px;
  border-radius: 2px;
}